import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts/layout';
import { getSrc } from 'gatsby-plugin-image';
import Hero from '../components/hero/hero';
import ContentSections from '../components/contentSections/contentSections';
import ContactDialog from '../components/dialog/contactDialog';
import NewsletterDialog from '../components/dialog/newsletterDialog';
import Seo from '../components/seo/seo';

import { injectIntl } from "gatsby-plugin-react-intl"

const IndexPage = ({ data, intl }) => {
  const locales = {
    en: "en-US",
    fi: "fi-FI",
  }
  const node = data.allContentfulHomepage.nodes.filter(item => item.node_locale === locales[intl.locale]);
  const formData = data.forms.edges.filter(item => item.node.node_locale === locales[intl.locale])[0].node;


  let newsitems = [];

  if (locales[intl.locale] === "en-US") {
    newsitems = data.newsitems.nodes.filter(item => ((item.node_locale === locales[intl.locale]) && !!item.whereContentWillBeShown &&
      (item.whereContentWillBeShown[0]) !== "Show only in Finnish")).slice(0, 3);
  } else {
    newsitems = data.newsitems.nodes.filter(item => ((item.node_locale === locales[intl.locale]) && !!item.whereContentWillBeShown &&
      (item.whereContentWillBeShown[0]) !== "Show only in English")).slice(0, 3);
  }

  let { title, heroImageOrVideo, contentSections, colorTheme, node_locale, seoSettings } = node[0];
  const seoImage = seoSettings?.image?.gatsbyImageData ? getSrc(seoSettings?.image?.gatsbyImageData) : null;

  const dialogRef = useRef(null);

  const openDialog = () => {
    if (typeof window !== 'undefined') {
      // Click the Send Message button, datalayer
      // when the 'Send Message' button is clicked
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': "sendMessageClick"
      });
    }
    dialogRef.current.openContactDialog();
  }

  const dialogRefNewsletter = useRef(null);

  const openNewsletterDialog = () => {
    dialogRefNewsletter.current.openNewsletterDialog();
  }

  return (
    <Layout colorTheme={colorTheme} nodeLocale={node_locale}>
      <Seo
        seoTitle="Edita Prima"
        seoTitle={seoSettings?.seoTitle ? seoSettings?.seoTitle : 'Edita Prima'}
        seoDesc={seoSettings?.description ? seoSettings?.description.description : title}
        keywords={seoSettings?.keywords ? seoSettings?.keywords : null}
        image={seoImage ? seoImage : null}
        url={typeof window !== 'undefined' ? window.location.href : ''}
        lang={node_locale.substr(0, 2)}
        noIndex={seoSettings?.hidePageFromSearchEnginesNoindex ? 'nofollow' : 'follow'}
        noFollow={seoSettings?.excludeLinksFromSearchRankingsNofollow ? 'noindex' : 'index'}
      />
      <Hero
        title={title}
        mediaType={heroImageOrVideo.file.contentType}
        image={heroImageOrVideo.gatsbyImageData}
        video={heroImageOrVideo}
      />
      <ContactDialog
        ref={dialogRef}
        sectionTitle={formData.sectionTitle}
        firstName={formData.firstName}
        lastName={formData.lastName}
        email={formData.email}
        phone={formData.phone}
        message={formData.message.message}
        texts={formData.texts}
      />
      <NewsletterDialog
        ref={dialogRefNewsletter}
        email={formData.email}
        texts={formData.texts}
      />
      <ContentSections contentSections={contentSections} newsitems={newsitems} nodeLocale={node_locale} openDialog={openDialog} openNewsletterDialog={openNewsletterDialog} />
    </Layout>
  )
}

export const query = graphql`
  query frontPageQuery {
    allContentfulHomepage {
      nodes {
        title {
          raw
        }
        heroImageOrVideo {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
          file {
            contentType
            url
          }
        }
        seoSettings {
        seoTitle
        description {
          description
        }
        keywords
        image {
          gatsbyImageData
        }
        excludeLinksFromSearchRankingsNofollow
        hidePageFromSearchEnginesNoindex
      }
        contentSections {
          ... on Node {
            ... on ContentfulBannerSection {
              __typename
              id
              banners {
                ... on Node {
                  ... on ContentfulBanner {
                    internal {
                      type
                    }
                    category
                    color
                    title
                    buttonLabel
                    buttonLink {
                      ... on Node {
                        ... on ContentfulPage {
                          id
                          slug
                        }
                      }
                    }
                    anchorLink
                  }
                  ... on ContentfulArticle {
                    internal{
                      type
                    }
                    id
                    excerpt
                    newsType
                    slug
                    linkText
                    color
                  }
                }
              }
            }
            ... on ContentfulServicesSection {
              __typename
              sectionTitle
              serviceArea {
                title
                text {
                  raw
                  references {
                    ... on ContentfulPage {
                      contentful_id
                      __typename
                      slug
                    }
                  }
                }
              }
              buttonLabel
              buttonLink {
                slug
              }
            }
          }
          ... on ContentfulCtaSection {
            __typename
            id
            title
            titleColor
            textField {
              textField
            }
            ctaLinks {
              ... on Node { 
                ... on ContentfulExternalLink {
                  linkName
                  linkUrl
                }
                ... on ContentfulInternalLink {
                  id
                  linkName
                  linkToEntry {
                    ... on ContentfulPage {
                      id
                      slug
                    }
                  }
                }
              }
            }
            textAlignCenter
            backgroundVideo {
              file {
                url
              }
            }
            videoAlphaChannel {
              file {
                url
              }
            }
          }
          ... on ContentfulTextSection {
            __typename
            id
            title
            richText {
              raw
            }
            ctaButtonLinks {
              ... on Node { 
                ... on ContentfulExternalLink {
                  linkName
                  linkUrl
                }
                ... on ContentfulInternalLink {
                  id
                  linkName
                  linkToEntry {
                    ... on ContentfulPage {
                      id
                      slug
                    }
                  }
                }
                ... on ContentfulAssetLink {
                  linkName
                  addAsset {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulHighlightsSection {
            __typename
            id
            items {
              key
              value
            }
          }
          ... on ContentfulRefrenceSection {
            __typename
            id
            sectionTitle
            logos {
              gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        colorTheme
        node_locale
      }
    }
    newsitems: allContentfulArticle (
      sort: {order: DESC, fields: date}
        filter: {
          slug: {ne: "systemfile"},
          title:{ne: null}
        }
      ) {
      nodes {
        internal{
          type
        }
        id
        excerpt
        newsType
        slug
        linkText
        color
        whereContentWillBeShown
        node_locale
      }
    }
    forms: allContentfulFormSection {
      edges {
        node {
          __typename
          id
          sectionTitle
          firstName
          lastName
          email
          phone
          message {
            message
          }
          texts {
            send,
            requiredFirstName,
            requiredLastName,
            invalidEmail,
            excludedEmail,
            requiredPhone,
            requiredMessage,
            thankyouMessage,
            existingContact,
            subscribeOurNewsletterTitle,
            subscribeOurNewsletter,
            thankyouNewsletterMessage,
            failedNewsletterMessage,
            simpleContactFormTitle
          }
          node_locale
        }
      }
    }
  }
`

export default injectIntl(IndexPage);