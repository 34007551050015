import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { RichText } from '../richText/richText';

const Hero = ({ title, image, video, mediaType, }) => {

  if (mediaType === 'image/jpeg') {
    return (
      <section className="hero mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20 relative w-full">
        {image &&
          <GatsbyImage
            image={image}
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', zIndex: '-1', height: '100%' }}
            imgStyle={{ objectFit: 'cover', objectPosition: '0% 0%' }}
          />
        }
        <div className="max-w-screen-xl mx-auto py-40 flex justify-center items-center">
          <div>
            <RichText raw={title} />
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className="hero mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20 relative w-full lg:min-h-screen">
        {video &&
          <video className="absolute top-0 left-0 w-full h-full object-cover z-0" width="100%" autoPlay loop muted playsInline webkitPlaysInline preload="auto">
            <source src={video.file.url} type="video/mp4" />
          </video>
        }
        <div className="h-full mx-auto py-40 flex justify-center items-center relative z-10">
          <div className="">
            <RichText raw={title} />
          </div>
        </div>
      </section>
    )
  }
}

export default Hero;